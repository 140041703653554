export const fullStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'SoftwareApplication',
  applicationCategory: 'Educational',
  applicationSubCategory: 'Educational Math',
  educationalUse: 'Math center',
  operatingSystem: 'Windows,iOS,MacOS,Android',
  interactivityType: 'active',
  educationalAlignment: [
    {
      '@type': 'AlignmentObject',
      alignmentType: 'educationalSubject',
      educationalFramework: 'Common Core Standards for Mathematics',
      targetName: 'Math',
      targetUrl: 'http://purl.org/ASN/scheme/ASNTopic/Math',
    },
    {
      '@type': 'AlignmentObject',
      alignmentType: 'educationalLevel',
      targetName: 'ElementarySchool',
      educationalFramework: 'Common Core Standards for Mathematics',
      targetUrl:
        'http://purl.org/ASN/scheme/ASNEducationLevel/ElementarySchool',
    },
  ],
  name: 'HappyNumbers.com',
  alternateName: 'Happy Numbers',
  hasPart: [
    {
      '@type': 'SoftwareApplication',
      applicationCategory: 'Educational',
      applicationSubCategory: 'Educational Math',
      operatingSystem: 'Windows,iOS,MacOS,Android',
      educationalUse: 'Math center',
      interactivityType: 'active',
      name: 'Pre Kindergarten Math - instruction and mathematics practice for Pre-kindergarten | HappyNumbers.com',
      educationalAlignment: [
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalSubject',
          targetName: 'Math',
          targetUrl: 'http://purl.org/ASN/scheme/ASNTopic/Math',
        },
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalLevel',
          targetName: 'Pre-kindergarten',
          educationalFramework: 'Common Core Standards for Mathematics',
          targetUrl: 'https://purl.org/ASN/scheme/ASNEducationLevel/Pre-K',
        },
      ],
      url: 'https://happynumbers.com/math/pre-kindergarten',
    },
    {
      '@type': 'SoftwareApplication',
      applicationCategory: 'Educational',
      applicationSubCategory: 'Educational Math',
      operatingSystem: 'Windows,iOS,MacOS,Android',
      educationalUse: 'Math center',
      interactivityType: 'active',
      name: 'Kindergarten Math - instruction and mathematics practice for Kindergarten | HappyNumbers.com',
      educationalAlignment: [
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalSubject',
          targetName: 'Math',
          targetUrl: 'http://purl.org/ASN/scheme/ASNTopic/Math',
        },
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalLevel',
          targetName: 'Kindergarten',
          educationalFramework: 'Common Core Standards for Mathematics',
          targetUrl: 'https://purl.org/ASN/scheme/ASNEducationLevel/K',
        },
      ],
      url: 'https://happynumbers.com/math/kindergarten',
    },
    {
      '@type': 'SoftwareApplication',
      applicationCategory: 'Educational',
      applicationSubCategory: 'Educational Math',
      operatingSystem: 'Windows,iOS,MacOS,Android',
      educationalUse: 'Math center',
      interactivityType: 'active',
      name: 'First Grade Math - instruction and mathematics practice for 1st grader | HappyNumbers.com',
      educationalAlignment: [
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalSubject',
          targetName: 'Math',
          targetUrl: 'http://purl.org/ASN/scheme/ASNTopic/Math',
        },
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalLevel',
          targetName: 'First Grade',
          educationalFramework: 'Common Core Standards for Mathematics',
          targetUrl: 'https://purl.org/ASN/scheme/ASNEducationLevel/1',
        },
      ],
      url: 'https://happynumbers.com/math/grade1',
    },
    {
      '@type': 'SoftwareApplication',
      applicationCategory: 'Educational',
      applicationSubCategory: 'Educational Math',
      operatingSystem: 'Windows,iOS,MacOS,Android',
      educationalUse: 'Math center',
      interactivityType: 'active',
      name: 'Second Grade Math - instruction and mathematics practice for 2nd grader | HappyNumbers.com',
      educationalAlignment: [
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalSubject',
          targetName: 'Math',
          targetUrl: 'http://purl.org/ASN/scheme/ASNTopic/Math',
        },
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalLevel',
          targetName: 'Second Grade',
          educationalFramework: 'Common Core Standards for Mathematics',
          targetUrl: 'https://purl.org/ASN/scheme/ASNEducationLevel/2',
        },
      ],
      url: 'https://happynumbers.com/math/grade2',
    },
    {
      '@type': 'SoftwareApplication',
      applicationCategory: 'Educational',
      applicationSubCategory: 'Educational Math',
      operatingSystem: 'Windows,iOS,MacOS,Android',
      educationalUse: 'Math center',
      interactivityType: 'active',
      name: 'Third Grade Math - instruction and mathematics practice for 3rd grader | HappyNumbers.com',
      educationalAlignment: [
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalSubject',
          targetName: 'Math',
          targetUrl: 'http://purl.org/ASN/scheme/ASNTopic/Math',
        },
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalLevel',
          targetName: 'Third Grade',
          educationalFramework: 'Common Core Standards for Mathematics',
          targetUrl: 'https://purl.org/ASN/scheme/ASNEducationLevel/3',
        },
      ],
      url: 'https://happynumbers.com/math/grade3',
    },
    {
      '@type': 'SoftwareApplication',
      applicationCategory: 'Educational',
      applicationSubCategory: 'Educational Math',
      operatingSystem: 'Windows,iOS,MacOS,Android',
      educationalUse: 'Math center',
      interactivityType: 'active',
      name: 'Fourth Grade Math - instruction and mathematics practice for 4th grader | HappyNumbers.com',
      educationalAlignment: [
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalSubject',
          targetName: 'Math',
          targetUrl: 'http://purl.org/ASN/scheme/ASNTopic/Math',
        },
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalLevel',
          targetName: 'Fourth Grade',
          educationalFramework: 'Common Core Standards for Mathematics',
          targetUrl: 'https://purl.org/ASN/scheme/ASNEducationLevel/4',
        },
      ],
      url: 'https://happynumbers.com/math/grade4',
    },
    {
      '@type': 'SoftwareApplication',
      applicationCategory: 'Educational',
      applicationSubCategory: 'Educational Math',
      operatingSystem: 'Windows,iOS,MacOS,Android',
      educationalUse: 'Math center',
      interactivityType: 'active',
      name: 'Fifth Grade Math - instruction and mathematics practice for 5th grader | HappyNumbers.com',
      educationalAlignment: [
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalSubject',
          targetName: 'Math',
          targetUrl: 'http://purl.org/ASN/scheme/ASNTopic/Math',
        },
        {
          '@type': 'AlignmentObject',
          alignmentType: 'educationalLevel',
          targetName: 'Fifth Grade',
          educationalFramework: 'Common Core Standards for Mathematics',
          targetUrl: 'https://purl.org/ASN/scheme/ASNEducationLevel/5',
        },
      ],
      url: 'https://happynumbers.com/math/grade5',
    },
  ],
};

export const shortStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'HappyNumbers.com',
  url: 'https://happynumbers.com',
  logo: 'https://happynumbers.com/small_logo_2x.png',
  sameAs: [
    'https://twitter.com/happynumbers',
    'https://www.facebook.com/happynumberscom',
  ],
};
